import lottie from 'lottie-web';

function iconAnimation () {
    const cards = document.querySelectorAll('.js-animate-icon');

    function initIcon(item) {
        const svgContainer = item.querySelector('.js-animate-container');

        function animateIcon(animation) {        
            animation.play();
        }
    
        function destroyAnimation(item, animation) {
            item.addEventListener('mouseenter', () => {
                animation.stop();
                animation.play();
            });
        }
        
        const animation = lottie.loadAnimation({
            container: svgContainer,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: '/Assets/images/data.json',
        });

        item.addEventListener('mouseenter', () => {
            animateIcon(animation);
        });
        item.addEventListener('mouseleave', () => {
            destroyAnimation(item, animation);
        });
    }

    cards.forEach(card => {
        initIcon(card);
    })
}

export default iconAnimation;
