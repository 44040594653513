import api from '../api/vacancies';

const vacancyOverviewModule = {
    loadNewsButton: null,
    newsContainer: null,
    newsTypeFilters: null,
    newsTypeFiltersMobile: null,
    newsTargetFilter: null,
    query: {
        p: 1,
        targetGroup: '',
        type: '',
        pageId: '',
        culture: '',
        sort: true,
        lastLoaded: false
    },
    init: () => {
        vacancyOverviewModule.newsContainer = document.querySelector('[data-module-vacancies]');
        vacancyOverviewModule.loadNewsButton = document.querySelector('[data-module-vacancies-load]');
        vacancyOverviewModule.newsTypeFilters = document.querySelectorAll('[data-module-vacancies-filter-type]');
        vacancyOverviewModule.newsTypeFiltersMobile = document.querySelector('[data-module-vacancies-filter-type-mobile]');
        vacancyOverviewModule.newsTargetFilter = document.querySelector('[data-module-vacancies-filter-target]');
        vacancyOverviewModule.query.pageId = document.querySelector('[data-module-pageid]').getAttribute('data-module-pageid');
        vacancyOverviewModule.query.culture = document.querySelector('[data-module-culture]').getAttribute('data-module-culture');

        if (vacancyOverviewModule.loadNewsButton) {
            vacancyOverviewModule.loadNewsButton.addEventListener('click', () => {
                vacancyOverviewModule.loadNewsItems(true);
            });

            vacancyOverviewModule.checkLastItem();
        }

        if (document.querySelector('.js-showold') !== null) {
            document.querySelector('.js-showold').addEventListener('click', () => {
                vacancyOverviewModule.query.sort = false;
                vacancyOverviewModule.query.lastLoaded = document.querySelector('[data-last]') ? true : false;
                vacancyOverviewModule.updateQueryUrl();
                vacancyOverviewModule.loadNewsItems(false);
            });
        }

        if (document.querySelector('.js-shownew') !== null) {
            document.querySelector('.js-shownew').addEventListener('click', () => {
                vacancyOverviewModule.query.sort = true;
                vacancyOverviewModule.query.lastLoaded = document.querySelector('[data-last]') ? true : false;
                vacancyOverviewModule.updateQueryUrl();
                vacancyOverviewModule.loadNewsItems(false);
            });
        }

        if (vacancyOverviewModule.newsTypeFilters) {
            vacancyOverviewModule.newsTypeFilters.forEach((filter) => {
                filter.addEventListener('click', (e) => {
                    vacancyOverviewModule.newsTypeFilters.forEach(filter => filter.classList.remove('news__filter--active'));
                    filter.classList.add('news__filter--active');
                    vacancyOverviewModule.setActiveTypeFilter(filter.getAttribute('data-module-vacancies-filter-type'));
                });
            });
        }

        if (vacancyOverviewModule.newsTypeFiltersMobile) {
            vacancyOverviewModule.newsTypeFiltersMobile.addEventListener('change', (e) => {
                vacancyOverviewModule.setActiveTypeFilter(e.target.value);
            });
        }

        if (vacancyOverviewModule.newsTargetFilter) {
            vacancyOverviewModule.newsTargetFilter.addEventListener('change', (e) => {
                vacancyOverviewModule.setActiveTargetFilter(e.target.value);
            });
        }
    },
    loadNewsItems: (loadMore) => {
        vacancyOverviewModule.toggleDisableLoadButton();

        if (loadMore) {
            vacancyOverviewModule.query.p += 1;
        }

        api.getNewsItems(vacancyOverviewModule.query).then((responseHtml) => {
            if(responseHtml && responseHtml.length) {
                if (loadMore) {
                    vacancyOverviewModule.newsContainer.insertAdjacentHTML('beforeend', responseHtml);
                } else {
                    vacancyOverviewModule.newsContainer.innerHTML = responseHtml;
                }

                vacancyOverviewModule.toggleDisableLoadButton();
                vacancyOverviewModule.updateQueryUrl();
                vacancyOverviewModule.checkLastItem();
            } else {
                vacancyOverviewModule.newsContainer.innerHTML = responseHtml;
                vacancyOverviewModule.toggleDisableLoadButton();
                vacancyOverviewModule.hideLoadButton();
            }
        });
    },
    setActiveTypeFilter: (type) => {
        vacancyOverviewModule.query.p = 1;
        vacancyOverviewModule.query.type = type;
        vacancyOverviewModule.loadNewsItems(false);
    },
    setActiveTargetFilter: (target) => {
        vacancyOverviewModule.query.p = 1;
        vacancyOverviewModule.query.targetGroup = target;
        vacancyOverviewModule.loadNewsItems(false);
    },
    updateQueryUrl: () => {
        const url = `?p=${vacancyOverviewModule.query.p}${vacancyOverviewModule.query.targetGroup.length ? `&targetGroup=${vacancyOverviewModule.query.targetGroup}` : ''}${vacancyOverviewModule.query.type.length ? `&type=${vacancyOverviewModule.query.type}` : ''}${!vacancyOverviewModule.query.sort ? `&sort=${vacancyOverviewModule.query.sort}` : ''}`;
        history.pushState(vacancyOverviewModule.query, '', `${document.location.pathname}${url}`);
    },
    checkLastItem: () => {
        if (document.querySelector('[data-last]')) {
            vacancyOverviewModule.hideLoadButton();
        } else {
            vacancyOverviewModule.showLoadButton();
        }
    },
    hideLoadButton: () => {
        if (!vacancyOverviewModule.loadNewsButton.classList.contains('d-none')) {
            vacancyOverviewModule.loadNewsButton.classList.add('d-none');
        }
    },
    showLoadButton: () => {
        if (vacancyOverviewModule.loadNewsButton.classList.contains('d-none')) {
            vacancyOverviewModule.loadNewsButton.classList.remove('d-none');
        }
    },
    toggleDisableLoadButton: () => {
        if (vacancyOverviewModule.loadNewsButton.getAttribute('disabled')) {
            vacancyOverviewModule.loadNewsButton.removeAttribute('disabled');
            vacancyOverviewModule.loadNewsButton.classList.remove('is-loading');
        } else {
            vacancyOverviewModule.loadNewsButton.setAttribute('disabled', 'disabled');
            vacancyOverviewModule.loadNewsButton.classList.add('is-loading');
        }
    }
}

export default vacancyOverviewModule;
