import './helpers';
import fInitCookie from 'js-cookie';
import stickyHeader from './modules/sticky-header';
import navModule from './modules/nav';
import accordionModule from './modules/accordion';
import videoModule from './modules/video';
import contentSlider from './modules/slider';
import iconAnimation from './modules/usp-icon';
import animationObserver from './modules/observer';
import vacancyOverviewModule from './modules/vacancies';
import newsOverviewModule from './modules/news';
import calcHeight from './modules/calcHeight';
import mobileNav from './modules/mobile-menu';

(function fPageLoad() {
    if (document.querySelectorAll('.js-cookie').length) { fInitCookie(); }
    window.addEventListener('load', () => {
        stickyHeader();
        navModule();
        accordionModule.init();

        if (document.querySelectorAll('.js-video-open').length) { videoModule(); }
        if (document.querySelectorAll('.js-slider').length) { contentSlider(); }
        if (document.querySelectorAll('.js-animate-icon').length) { iconAnimation(); }
        if (document.querySelectorAll('[data-observe-animation]').length) { animationObserver(); }
        if (document.querySelectorAll('[data-module-vacancies]').length) { vacancyOverviewModule.init(); }
        if (document.querySelectorAll('[data-module-news]').length) { newsOverviewModule.init(); }
        if (document.querySelectorAll('[data-mobile-menu-trigger]').length) { mobileNav(); }
        if (document.querySelectorAll('[data-calc-height]').length) { calcHeight(); }
    });
}());
