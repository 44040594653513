
function calcHeight () {
    const wrapperElements = document.querySelectorAll('[data-calc-height]');
    const mobileBreakpoint = 992;
    let screenWidth = document.body.getBoundingClientRect().width;
    const heights = [];

    function calculateHeight(wrapper) {
        const items = wrapper.querySelectorAll('[data-calc-height-item]');

        items.forEach(item => {
            heights.push(item.offsetHeight);
        });

        const maxHeight = Math.max.apply(null, heights);

        if(wrapper.dataset.calcMobile !== undefined && screenWidth <= mobileBreakpoint) {
            wrapper.style.height = maxHeight + 45 + 'px';
        } else {
            wrapper.style.height = maxHeight + 'px';
        }
    }

    function initElements() {
        if (wrapperElements) {
            for(let i = 0; i < wrapperElements.length; i+=1) {
                calculateHeight(wrapperElements[i]);
            }
        }
    }

    initElements();

    window.addEventListener('resize', function() {
        screenWidth = document.body.getBoundingClientRect().width;

        initElements();
    });
}

export default calcHeight;
