﻿import { gsap } from 'gsap';

function fVideo() {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://www.youtube.com/iframe_api';

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

    function offset(el) {
        const rect = el.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    function createPlayer(youtubeId) {
        const player = new YT.Player(youtubeId, {
            videoId: youtubeId,
            playerVars: {
                rel: 0,
                controls: 0,
                modestbranding: 1,
                autoplay: 1,
                showinfo: 0,
                start: 0
            }
        });
    }

    function animateModalIn(modal, backdrop, sourceDimensions, youtubeId) {
        let videoWidth = (window.innerWidth * 80) / 100;
        let videoHeight = (videoWidth * 9) / 16;

        if (videoHeight > window.innerHeight) {
            videoHeight = (window.innerHeight * 80) / 100;
            videoWidth = (videoHeight * 16) / 9;
        }

        function fixModal() {
            modal.style.position = 'fixed';
            modal.style.top = `${(window.innerHeight - (videoHeight)) / 2}px`;
        }

        const timeline = gsap.timeline({});
        timeline.timeScale(3);
        timeline.defaultEase = 'power3.inOut';

        timeline.set(modal, {
            display: 'block',
            top: sourceDimensions.y,
            left: sourceDimensions.x,
            width: sourceDimensions.width,
            height: sourceDimensions.height,
        }).to(modal, 1, {
            autoAlpha: 1,
            ease: 'power3.out'
        }).to(modal, 1, {
            top: `${((window.innerHeight - (videoHeight)) / 2) + window.pageYOffset}px`,
            left: '0px',
            right: '0px',
            height: `${videoHeight}px`,
            width: `${videoWidth}px`,
            onComplete: fixModal
        }, '-=0.5').to(backdrop, 1, {
            autoAlpha: 1,
            onComplete: createPlayer,
            onCompleteParams: [youtubeId],
        }, '-=1');
    }

    function animateModalOut(modal, backdrop, video) {
        const iframe = modal.querySelector('iframe');

        const timeline = gsap.timeline({});
        timeline.timeScale(2);
        timeline.defaultEase = 'power3.power3.inOut';

        function destroyModal() {
            modal.remove();
            backdrop.remove();
            if (video !== null) {
                video.play();
            }
        }        

        timeline.to(iframe, 0.8, {
            autoAlpha: 0
        }).to(backdrop, 1, {
            autoAlpha: 0
        }).to(modal, .7, {
            scaleX: .8
        }, '-=0.8').to(modal, .7, {
            y: '150%',
            onComplete: destroyModal
        }, '-=0.4');
    }

    function createModal(button) {
        // Get source and its dimensions and offset
        const youtubeId = button.getAttribute('data-video-id');
        const videoHeader = button.closest('.js-video-hero');
        const backgroundVideo = button.closest('.js-background-video');
        let currentVideo = null;

        let source = null;

        if (!videoHeader) {
            source = button.closest('.js-video-source');
        } else {
            source = videoHeader;
        }
        
        if (!source || !youtubeId) return;

        if (backgroundVideo) {
            backgroundVideo.forEach((video) => {
                if (video.dataset.modalVideoId === youtubeId) {
                    video.pause();
                    currentVideo = video;
                }
            });
        }

        const sourceDimensions = {
            width: source.offsetWidth,
            height: source.offsetHeight,
            x: offset(source).left,
            y: offset(source).top
        }

        // Create a modal and backdrop
        const modal = document.createElement('div');
        const modalClose = document.createElement('button');
        const videoFrame = document.createElement('div');
        const backdrop = document.createElement('div');

        // Add modal to body
        modal.setAttribute('class', 'modal modal--video');
        document.body.appendChild(modal);

        // Add closer button to modal
        modalClose.classList.add('modal__close');
        modalClose.innerHTML = '<svg class="modal__close__icon"><use xlink:href="#icon-close"></use></svg>';
        modal.appendChild(modalClose);

        // Add video frame to modal
        videoFrame.id = youtubeId;
        modal.appendChild(videoFrame);

        // Add backdrop to body
        backdrop.classList.add('backdrop');
        if (button.classList.contains('js-video-is-home')) {
            backdrop.classList.add('is-home');
        }
        document.body.appendChild(backdrop);

        // Animate them
        animateModalIn(modal, backdrop, sourceDimensions, youtubeId);

        // Add event listeners to backdrop & closer to close modal
        window.addEventListener('click', function (event) {
            if (event.target.closest('.backdrop') || event.target.closest('.modal__close')) {
                animateModalOut(modal, backdrop, currentVideo);
            }
        });
    }

    window.addEventListener('click', function (event) {
        if (event.target.closest('.js-video-open')) {
            createModal(event.target.closest('.js-video-open'));
        }
    });
}

export default fVideo;