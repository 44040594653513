﻿import api from '../api/news';

const newsOverviewModule = {
    loadNewsButton: null,
    newsContainer: null,
    newsTypeFilters: null,
    newsTypeFiltersMobile: null,
    newsTargetFilter: null,
    query: {
        p: 1,
        targetGroup: '',
        type: '',
        tag: '',
        pageId: '',
        culture: '',
        sort: true
    },
    init: () => {
        newsOverviewModule.newsContainer = document.querySelector('[data-module-news]');
        newsOverviewModule.loadNewsButton = document.querySelector('[data-module-news-load]');
        newsOverviewModule.newsTypeFilters = document.querySelectorAll('[data-module-news-filter-type]');
        newsOverviewModule.newsTypeFiltersMobile = document.querySelector('[data-module-news-filter-type-mobile]');
        newsOverviewModule.newsTargetFilter = document.querySelector('[data-module-news-filter-target]');
        newsOverviewModule.query.pageId = document.querySelector('[data-module-pageid]').getAttribute('data-module-pageid');
        newsOverviewModule.query.culture = document.querySelector('[data-module-culture]').getAttribute('data-module-culture');

        if (newsOverviewModule.loadNewsButton) {
            newsOverviewModule.loadNewsButton.addEventListener('click', () => {
                newsOverviewModule.loadNewsItems(true);
            });

            newsOverviewModule.checkLastItem();
        }

        if (newsOverviewModule.newsTypeFilters) {
            newsOverviewModule.newsTypeFilters.forEach((filter) => {
                filter.addEventListener('click', (e) => {
                    newsOverviewModule.newsTypeFilters.forEach(filter => filter.classList.remove('news__filter--active'));
                    filter.classList.add('news__filter--active');
                    newsOverviewModule.setActiveTypeFilter(filter.getAttribute('data-module-news-filter-type'));
                });
            });
        }

        if (newsOverviewModule.newsTypeFiltersMobile) {
            newsOverviewModule.newsTypeFiltersMobile.addEventListener('change', (e) => {
                newsOverviewModule.setActiveTypeFilter(e.target.value);
            });
        }

        if (newsOverviewModule.newsTargetFilter) {
            newsOverviewModule.newsTargetFilter.addEventListener('change', (e) => {
                newsOverviewModule.setActiveTargetFilter(e.target.value);
            });
        }
    },
    loadNewsItems: (loadMore) => {
        newsOverviewModule.toggleDisableLoadButton();

        if (loadMore) {
            newsOverviewModule.query.p += 1;
        }

        api.getNewsItems(newsOverviewModule.query).then((responseHtml) => {
            if(responseHtml && responseHtml.length) {
                if (loadMore) {
                    newsOverviewModule.newsContainer.insertAdjacentHTML('beforeend', responseHtml);
                } else {
                    newsOverviewModule.newsContainer.innerHTML = responseHtml;
                }

                newsOverviewModule.toggleDisableLoadButton();
                newsOverviewModule.updateQueryUrl();
                newsOverviewModule.checkLastItem();
            } else {
                newsOverviewModule.newsContainer.innerHTML = responseHtml;
                newsOverviewModule.toggleDisableLoadButton();
                newsOverviewModule.hideLoadButton();
            }
        });
    },
    setActiveTypeFilter: (type) => {
        newsOverviewModule.query.p = 1;
        newsOverviewModule.query.type = type;
        newsOverviewModule.loadNewsItems(false);
    },
    setActiveTagFilter: (tag) => {
        newsOverviewModule.query.p = 1;
        newsOverviewModule.query.tag = tag;
        newsOverviewModule.loadNewsItems(false);
    },
    setActiveTargetFilter: (target) => {
        newsOverviewModule.query.p = 1;
        newsOverviewModule.query.targetGroup = target;
        newsOverviewModule.loadNewsItems(false);
    },
    updateQueryUrl: () => {
        const url = `?p=${newsOverviewModule.query.p}${newsOverviewModule.query.targetGroup.length ? `&targetGroup=${newsOverviewModule.query.targetGroup}` : ''}${newsOverviewModule.query.type.length ? `&type=${newsOverviewModule.query.type}` : ''}`;
        history.pushState(newsOverviewModule.query, '', `${document.location.pathname}${url}`);
    },
    checkLastItem: () => {
        if (document.querySelector('[data-last]')) {
            newsOverviewModule.hideLoadButton();
        } else {
            newsOverviewModule.showLoadButton();
        }
    },
    hideLoadButton: () => {
        if (!newsOverviewModule.loadNewsButton.classList.contains('d-none')) {
            newsOverviewModule.loadNewsButton.classList.add('d-none');
        }
    },
    showLoadButton: () => {
        if (newsOverviewModule.loadNewsButton.classList.contains('d-none')) {
            newsOverviewModule.loadNewsButton.classList.remove('d-none');
        }
    },
    toggleDisableLoadButton: () => {
        if (newsOverviewModule.loadNewsButton.getAttribute('disabled')) {
            newsOverviewModule.loadNewsButton.removeAttribute('disabled');
            newsOverviewModule.loadNewsButton.classList.remove('is-loading');
        } else {
            newsOverviewModule.loadNewsButton.setAttribute('disabled', 'disabled');
            newsOverviewModule.loadNewsButton.classList.add('is-loading');
        }
    }
}

export default newsOverviewModule;