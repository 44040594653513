function animationObserver () {
    const animationElements = document.querySelectorAll('[data-observe-animation]');

    function animate(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                if (entry.target.dataset.observeAnimationDelay !== undefined) {
                    setTimeout(() => {
                        entry.target.querySelector('.feature-img__wrapper').classList.add('is-visible');
                    }, 500);
                } else if (entry.target.dataset.observeAnimationDelayLast !== undefined) {
                    setTimeout(() => {
                        entry.target.querySelector('.feature-img__wrapper').classList.add('is-visible');
                    }, 800);
                } else {
                    entry.target.querySelector('.feature-img__wrapper').classList.add('is-visible');
                }

                observer.unobserve(entry.target);
            }
        });
    }

    if (animationElements) {
        const observer = new IntersectionObserver(animate, {
            threshold: 0.5
        });

        for(let i = 0; i < animationElements.length; i+=1) {
            observer.observe(animationElements[i]);
        }
    }
}

export default animationObserver;
