const mobileNav = () => {
    const trigger = document.querySelector('[data-mobile-menu-trigger]');
    const menuWrapper = document.querySelector('[data-mobile-wrapper]');
    const subMenuTriggers = document.querySelectorAll('[data-mobile-nav-dropdown-trigger]');
    const subMenus = document.querySelectorAll('[data-mobile-nav-dropdown]');

    trigger.addEventListener('click', () => {
        if (trigger.classList.contains('is-active')) {
            trigger.classList.remove('is-active');
            menuWrapper.classList.remove('is-active');
            subMenus.forEach(sub => {
                sub.classList.remove('is-active');
            });
            document.body.classList.remove('no-scroll');
            document.documentElement.classList.remove('no-scroll');
        } else {
            trigger.classList.add('is-active');
            menuWrapper.classList.add('is-active');
            document.documentElement.classList.add('no-scroll');
            document.body.classList.add('no-scroll');
        }
    });

    subMenuTriggers.forEach(trigger => {
        const subMenu = trigger.parentElement.querySelector('[data-mobile-nav-dropdown]');

        trigger.addEventListener('click', () => {
            subMenu.classList.add('is-active');
        });

        const backButton = trigger.parentElement.querySelector('[data-mobile-nav-back]');
        backButton.addEventListener('click', () => {
            subMenu.classList.remove('is-active');
        });
    });
}

export default mobileNav;